import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Link from 'components/Link';
import styles from 'pages/pages.module.css';
import s from './GetStarted.module.css';
import WL from 'constants/whiteLabel';

class GetStarted extends React.Component {
  render () {
    return (
      <section className={[styles.content, s.getStarted].join(' ')}>
        <div className={styles.wrapper}>
          <div className={s.call}>
            <h3 className={cx(styles.accent, s.accent)}>
              <FormattedMessage id="ready_to_start" defaultMessage="Ready to get started?" />
            </h3>
            <p>
              <FormattedMessage id="free_home_mining" defaultMessage="Free for home mining! " />
              {' '}
              <Link withLang to="/pricing" className={styles.link}><FormattedMessage id="pay_as_grow" defaultMessage="Pay as you grow" /></Link>
            </p>
          </div>
          <div className={s.btnHolder}>
            <Link external noRef href={WL.getRegisterLink()} className={cx("btn-primary-big", s.startButton)} target={WL.regLinkTarget}>
              <FormattedMessage id="sign_up_free" defaultMessage="Sign up for free" />
            </Link>
          </div>
        </div>
      </section>
    )
  }
}

export default GetStarted;
